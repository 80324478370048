.customModal {
    width: 89%;

    > button > svg {
        fill: #233C98;
    }

    @media (min-width: 769px) {
        min-width: 50%;
        width: unset;
    }

    @media (min-width: 1200px) {
        min-width: 30%;
        width: unset;
    }
}
